import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '183, 28, 28',
		'primary-dark': '127, 0, 0',
		'accent': '255, 171, 0',
		'accent-plus': '7, 7, 7'
	}
});
